import Vue from 'vue'
import App from './App.vue'
import router from './router'

import vant from 'vant'
import 'vant/lib/index.css'
Vue.config.productionTip = false
const url = document.location.origin

// if (url.indexOf('yiyu-info.com') > 0) {
//   window.httpUrl = 'https://4s.store.yiyu-info.com/api'
// } else {
//   window.httpUrl = 'https://4s-store.alrfut.com/api'
// }

if (process.env.NODE_ENV == 'development' || process.env.NODE_ENV == 'test') {
  window.httpUrl = 'https://test.4s.store.yiyu-info.com/api'
  window.api = 'https://test.admin-4s.store.yiyu-info.com'
} else {
  window.httpUrl = 'https://4s.store.yiyu-info.com/api'
  window.api = 'https://admin-4s.store.yiyu-info.com'
}
// window.httpUrl = 'https://4s.store.yiyu-info.com/api'
// window.api = 'https://admin-4s.store.yiyu-info.com'

Vue.use(vant)

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
