import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      title: '报案位置',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue'),
  },
  {
    path: '/playphone',
    name: 'phone',
    meta: {
      title: '联系车主',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/playphone'),
  },
  {
    path: '/upInfo',
    name: 'upInfo',
    meta: {
      title: '上报位置',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/uploadInfo'),
  },
  {
    path: '/register',
    name: 'register',
    meta: {
      title: '车险信息登记',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue'),
  },
  // {
  //   path: '/beforregister',
  //   name: 'beforregister',
  //   meta: {
  //     title: '手机号验证',
  //   },
  //   component: () => import(/* webpackChunkName: "about" */ '../views/beforRegister.vue'),
  // },
  {
    path: '/beforregister',
    name: 'beforregister',
    meta: {
      title: '手机号验证',
    },
    component: () => import(/* webpackChunkName: "about" */ '../views/befor-register.vue'),
  },
  {
    path: '/search',
    name: 'search',
    meta: {
      title: '维修进度查询',
    },
    component: () => import(/* webpackChunkName: "searchList" */ '../views/searchList.vue'),
  },
  {
    path: '/search-list',
    name: 'search',
    meta: {
      title: '维修进度查询',
    },
    component: () => import(/* webpackChunkName: "searchList" */ '../views/search-list.vue'),
  },
  {
    path: '/searchhistory',
    name: 'search1',
    meta: {
      title: '维修进度查询',
    },
    component: () => import(/* webpackChunkName: "searchHistory" */ '../views/searchHistory.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
router.beforeEach((to, from, next) => {
  /* 路由发生变化修改页面title */
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

export default router
